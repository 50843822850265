// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap'
import '../stylesheets/application'

require('jquery');
require("jquery-ui/ui/widgets/datepicker");

Rails.start()
//Turbolinks.start()
ActiveStorage.start()

$(document).ready(function () {
    console.log("test");

    $('.date-picker').datepicker({
        format: 'dd/mm/yyyy'
    });

    if (document.getElementById('number-form')) {
        console.log("number-form");

        var payjp = Payjp("pk_live_4c3ed5ed330e69a4a2c1df06")
        console.log(payjp)
        var elements = payjp.elements();

        var numberElement = elements.create('cardNumber');
        var expiryElement = elements.create('cardExpiry');
        var cvcElement = elements.create('cardCvc');

        numberElement.mount('#number-form');
        expiryElement.mount('#expiry-form');
        cvcElement.mount('#cvc-form');

        var submit_btn = $("#info_submit");
        submit_btn.click(function (e) {

            console.log("click")
            var confirm = window.confirm('寄付金額をクレジットカードで決済します。宜しいですか？');
            e.preventDefault();

            if (confirm) {
                payjp.createToken(numberElement).then(function (response) {

                    if (response.error) {
                        alert(response.error.message)
                        regist_card.prop('disabled', false)
                    } else {
                        $("#card_token").append(
                            `<input type="hidden" name="payjp_token" value=${response.id}>
            <input type="hidden" name="card_token" value=${response.card.id}>`
                        );
                        $('#card_form')[0].submit();
                        $("#card_number").removeAttr("name");
                        $("#cvc-from").removeAttr("name");
                        $("#exp_month").removeAttr("name");
                        $("#exp_year").removeAttr("name");
                    }
                    ;
                });
            }
        });

    }

    $("#donate_post_code").jpostal({
        postcode: ["#donate_post_code"],
        address: {
            "#donate_prefecture_code": "%3",
            "#donate_city": "%4",
            "#donate_address_1": "%5%6%7"
        }
    })
});